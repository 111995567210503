import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Container from 'components/global/Container'
import CrossBtnIcon from 'assets/icons/CrossBtn'
import AgencyChoice from './ContactSteps/AgencyChoice'
import ContactForm from './ContactSteps/ContactForm'
import FormFeedback from './ContactSteps/FormFeedback'

const ContactModal = ({ isVisible, callBackQuit }) => {

  const contentRef = useRef(0);
  const [modalHeight, setModalHeight] = useState(0)
  const [isOpacity, setIsOpacity] = useState(true)
  const [step, setStep] = useState(1)
  // Default agency
  const [agency, setAgency] = useState('Montpellier')
  const transitionDuration = 400

  useEffect(() => {
    setTimeout(() => {
      setStep(1)
      setModalHeight(contentRef.current.scrollHeight)
    }, transitionDuration)
  }, [isVisible]);

  const handleAgency = (agency) => {
    setAgency(agency)
  }

  const handleStepTransition = (step) => {
    setIsOpacity(false)
    setStep(step)
    setTimeout(() => {
      setIsOpacity(true)
    }, transitionDuration)
  }

  useEffect(() => {
    setModalHeight(contentRef.current.scrollHeight)
  }, [step]);

  return (
    <StyledContainer isVisible={isVisible}>
      <Container>
        <StyledModal>
          <StyledExitbutton onClick={() => callBackQuit()}>
            <CrossBtnIcon />
          </StyledExitbutton>
          <StyledStepsContainer modalHeight={modalHeight} >
            <div ref={contentRef}>
              <StyledStepContainer isOpacity={isOpacity} isDisplayed={step === 1}>
                <AgencyChoice nextStep={() => handleStepTransition(2)} selectedAgency={handleAgency} defaultAgency={agency} />
              </StyledStepContainer>
              <StyledStepContainer isOpacity={isOpacity} isDisplayed={step === 2}>
                <ContactForm prevStep={() => handleStepTransition(1)} agency={agency} nextStep={() => handleStepTransition(3)} />
              </StyledStepContainer>
              <StyledStepContainer isOpacity={isOpacity} isDisplayed={step === 3}>
                <FormFeedback callBackQuit={callBackQuit} />
              </StyledStepContainer>
            </div>
          </StyledStepsContainer>
        </StyledModal>
      </Container>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Transition */
  visibility:  ${props => props.isVisible ? 'visible' : 'hidden'};
  opacity: ${props => props.isVisible ? 1 : 0};
  transition: all .4s ${props => props.theme.baseCubicBezier};
`
const StyledModal = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  border-radius: ${props => props.theme.baseBorderRadius};
  text-align: center;
  margin: 15px 0;
  ${breakpoint('md')`
    width: 565px;
  `}
`
const StyledExitbutton = styled.button`
  cursor: pointer;
  margin-left: auto;
  padding: 30px 30px 0 0;
`
const StyledStepsContainer = styled.div`
  height: ${props => props.modalHeight + 'px'};
  transition: height .4s ${props => props.theme.baseCubicBezier};
`
const StyledStepContainer = styled.div`
  display:  ${props => props.isDisplayed ? 'block' : 'none'};
  opacity: ${props => props.isOpacity ? 1 : 0};
  transition: all .4s ${props => props.theme.baseCubicBezier};
  padding: 0 30px 30px;
  ${breakpoint('md')`
    padding: 0 90px 30px 90px;
  `}
`


export default ContactModal
