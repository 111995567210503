import React from 'react';
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';

const Heading = ({ two, section, children, className, alignLeft }) => {
  if (two) return <HeadingTwo className={className}>{children}</HeadingTwo>
  if (section) return <SectionHeading className={className} alignLeft={alignLeft}>{children}</SectionHeading>
  return <HeadingOne className={className}>{children}</HeadingOne>
}

const HeadingOne = styled.h1`
  font-size: 32px;
  color: ${props => props.theme.colors.black};
  font-weight: 900;
  margin: 0 0 30px;
  line-height: 38px;
  ${breakpoint('md')`
    line-height: 55px;
    font-size: 46px;
  `}
`
const SectionHeading = styled.h2`
  font-size: 22px;
  color: ${props => props.theme.colors.black};
  font-weight: 900;
  margin: 120px 0 70px;
  line-height: 34px;
  text-transform: uppercase;
  text-align: ${props => props.alignLeft ? 'left' : 'center'};
  letter-spacing: .08rem;
`
const HeadingTwo = styled.h2`
  font-size: 20px;
  color: ${props => props.theme.colors.black};
  font-weight: 900;
  margin: 0 0 15px;
  line-height: 27px;
`

export default Heading;
