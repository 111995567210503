import React from 'react'

const MailBox = () => {
  return (
    <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M84.209 15.6667H62.6673V11.75H68.5423C71.7814 11.75 74.4173 9.11408 74.4173 5.875V1.95833C74.4173 0.875375 73.54 0 72.459 0H60.709C59.628 0 58.7506 0.875375 58.7506 1.95833V15.6667H35.2507C29.8515 15.6667 25.459 20.0592 25.459 25.4583H29.3757C29.3757 22.2192 32.0116 19.5833 35.2507 19.5833H37.209C40.4481 19.5833 43.084 22.2192 43.084 25.4583V60.7083C43.084 61.7913 43.9613 62.6667 45.0423 62.6667H51.1993L52.1648 68.8432C52.3136 69.7989 53.1341 70.5 54.0996 70.5H56.7923V94H60.709V70.5H64.6256V94H68.5423V70.5H71.235C72.2005 70.5 73.021 69.7989 73.1699 68.8432L74.1353 62.6667H88.1256C91.3647 62.6667 94.0006 60.0308 94.0006 56.7917V25.4583C94.0006 20.0592 89.6081 15.6667 84.209 15.6667ZM62.6673 3.91667H70.5006V5.875C70.5006 6.956 69.6214 7.83333 68.5423 7.83333H62.6673V3.91667ZM69.5587 66.5833H55.7759L55.1649 62.6667H70.1716L69.5587 66.5833ZM90.084 56.7917C90.084 57.8727 89.2047 58.75 88.1256 58.75H47.0007V25.4583C47.0007 23.2552 46.2682 21.2205 45.0384 19.5833H58.7506V27.777C56.477 28.5878 54.834 30.7419 54.834 33.2917C54.834 36.5308 57.4699 39.1667 60.709 39.1667C63.9481 39.1667 66.584 36.5308 66.584 33.2917C66.584 30.7419 64.9409 28.5878 62.6673 27.777V19.5833H84.209C87.4481 19.5833 90.084 22.2192 90.084 25.4583V56.7917ZM62.6673 33.2917C62.6673 34.3727 61.788 35.25 60.709 35.25C59.6299 35.25 58.7506 34.3727 58.7506 33.2917C58.7506 32.2107 59.6299 31.3333 60.709 31.3333C61.788 31.3333 62.6673 32.2107 62.6673 33.2917Z" fill="#364350" />
      <path d="M86.166 50.9167H74.416V54.8333H86.166V50.9167Z" fill="#364350" />
      <path d="M70.4993 50.9167H62.666V54.8333H70.4993V50.9167Z" fill="#364350" />
      <path d="M0 31.3333V58.75C0 60.91 1.75662 62.6667 3.91667 62.6667H39.1667V58.75H3.91667V37.2847L10.5162 42.6466C10.8648 42.9286 11.3015 43.0833 11.75 43.0833H19.5833V39.1667H12.4452L3.91667 32.2361V31.3333H39.1667V27.4167H3.91667C1.75662 27.4167 0 29.1733 0 31.3333Z" fill="#364350" />
      <path d="M39.1667 39.1667H23.5V43.0833H39.1667V39.1667Z" fill="#364350" />
      <path d="M19.5833 50.9167H11.75V54.8333H19.5833V50.9167Z" fill="#364350" />
    </svg>
  )
}

export default MailBox



