import React from 'react';

function Index() {
  return (
    <svg viewBox="0 0 50 47" fill="#C91200" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66667 22.5001H8.33333V20.8335H1.66667V16.4058L9.46584 13.4062L8.86749 11.8506L0.534156 15.0557C0.376946 15.1162 0.241751 15.2229 0.146395 15.3617C0.0510382 15.5006 -2.90522e-06 15.665 1.24026e-10 15.8335V24.1668H1.66667V22.5001Z" />
      <path d="M48.3333 26.667H38.3333C38.1123 26.667 37.9004 26.7548 37.7441 26.9111C37.5878 27.0673 37.5 27.2793 37.5 27.5003V34.167C37.5 34.388 37.5878 34.6 37.7441 34.7562C37.9004 34.9125 38.1123 35.0003 38.3333 35.0003H48.3333V43.7869L38.6325 40.0559C38.4422 39.9827 38.2316 39.9818 38.0407 40.0534L29.1667 43.3812V39.167C29.1667 39.0257 29.1307 38.8866 29.0622 38.763C28.9937 38.6394 28.8949 38.5352 28.775 38.4603L22.1083 34.2936C21.9759 34.2109 21.8228 34.167 21.6667 34.167H18.3333C18.1123 34.167 17.9004 34.2548 17.7441 34.4111C17.5878 34.5673 17.5 34.7793 17.5 35.0003V42.1311L12.5 40.2561V35.0003C12.5 34.7793 12.4122 34.5673 12.2559 34.4111C12.0996 34.2548 11.8877 34.167 11.6667 34.167H1.66667V29.167H15C15.221 29.167 15.433 29.0792 15.5893 28.9229C15.7455 28.7666 15.8333 28.5547 15.8333 28.3337V24.167H14.1667V27.5003H1.66667V25.8337H0V45.8337C-7.14665e-07 45.9735 0.0351913 46.1111 0.102334 46.2338C0.169476 46.3564 0.26641 46.4602 0.384205 46.5356C0.502 46.611 0.636869 46.6555 0.776385 46.665C0.915902 46.6746 1.05558 46.6489 1.18255 46.5903L11.6999 41.7361L24.7074 46.614C24.8961 46.6847 25.1039 46.6847 25.2926 46.614L38.3296 41.7251L48.8675 45.7781C48.9937 45.8267 49.1299 45.8438 49.2642 45.828C49.3985 45.8121 49.527 45.7638 49.6384 45.6873C49.7499 45.6107 49.8411 45.5082 49.9041 45.3885C49.9671 45.2688 50 45.1356 50 45.0003V25.0003H48.3333V26.667ZM1.66667 35.8337H10.8333V40.3005L1.66667 44.5312V35.8337ZM25 44.9437L19.1667 42.7562V35.8337H21.4277L27.5 39.6289V44.0062L25 44.9437ZM39.1667 33.3337V28.3337H48.3333V33.3337H39.1667Z" />
      <path d="M49.4665 15.0552L38.6332 10.8885C38.4578 10.8211 38.2648 10.8149 38.0854 10.8709L25.0007 14.9599L22.7492 14.2564L22.2521 15.8471L24.7521 16.6284C24.9139 16.6789 25.0874 16.6789 25.2492 16.6284L30.834 14.8831V31.7759L24.6296 27.6396C24.4927 27.5483 24.3318 27.4996 24.1673 27.4997H22.5007V24.1663H20.834V28.333C20.834 28.554 20.9218 28.766 21.0781 28.9222C21.2343 29.0785 21.4463 29.1663 21.6673 29.1663H23.915L31.2051 34.0263C31.3306 34.11 31.4764 34.158 31.6271 34.1653C31.7777 34.1726 31.9275 34.1389 32.0605 34.0677C32.1935 33.9965 32.3047 33.8906 32.3822 33.7612C32.4597 33.6318 32.5006 33.4838 32.5007 33.333V14.3623L37.5007 12.7998V20.833C37.5007 21.054 37.5884 21.266 37.7447 21.4222C37.901 21.5785 38.113 21.6663 38.334 21.6663H48.334V23.333H50.0007V15.833C50.0007 15.6645 49.9496 15.5001 49.8543 15.3612C49.7589 15.2224 49.6237 15.1157 49.4665 15.0552ZM39.1673 19.9997V12.8797L48.334 16.4053V19.9997H39.1673Z" />
      <path d="M13.438 16.2195C11.3072 16.7725 9.99935 17.8602 9.99935 19.1667C9.99935 19.7586 10.2869 20.8749 12.213 21.7004C13.4162 22.216 14.9976 22.5 16.666 22.5C18.3345 22.5 19.9159 22.216 21.119 21.7004C23.0451 20.8749 23.3327 19.7586 23.3327 19.1667C23.3327 17.847 22.0465 16.7696 19.8954 16.2179C20.1429 15.9322 20.3963 15.6295 20.6501 15.3123C22.9831 12.396 24.166 9.76756 24.166 7.5C24.166 5.51088 23.3758 3.60322 21.9693 2.1967C20.5628 0.790177 18.6551 0 16.666 0C14.6769 0 12.7692 0.790177 11.3627 2.1967C9.95619 3.60322 9.16602 5.51088 9.16602 7.5C9.16602 9.76756 10.3489 12.396 12.6819 15.3123C12.9362 15.6301 13.19 15.9333 13.438 16.2195ZM21.666 19.1667C21.666 19.7542 19.7677 20.8333 16.666 20.8333C13.5643 20.8333 11.666 19.7542 11.666 19.1667C11.666 18.7264 12.7604 17.9677 14.7522 17.6476C15.5279 18.4425 16.0776 18.9257 16.1173 18.9605C16.2692 19.0934 16.4642 19.1667 16.666 19.1667C16.8679 19.1667 17.0629 19.0934 17.2148 18.9605C17.2545 18.9257 17.8042 18.4424 18.5799 17.6474C20.7569 17.9942 21.666 18.7976 21.666 19.1667ZM16.666 1.66667C18.2126 1.66842 19.6953 2.28356 20.7889 3.37715C21.8825 4.47073 22.4976 5.95344 22.4993 7.5C22.4993 9.34792 21.4155 11.6823 19.3649 14.2507C18.5295 15.2898 17.6279 16.2739 16.6658 17.197C15.71 16.2801 14.814 15.3028 13.9834 14.2711C11.9222 11.6945 10.8327 9.35318 10.8327 7.5C10.8344 5.95344 11.4496 4.47073 12.5432 3.37715C13.6367 2.28356 15.1195 1.66842 16.666 1.66667Z" />
      <path d="M16.6673 10.8337C17.3266 10.8337 17.9711 10.6382 18.5192 10.2719C19.0674 9.90562 19.4946 9.38503 19.7469 8.77594C19.9992 8.16685 20.0652 7.49663 19.9366 6.85003C19.808 6.20342 19.4905 5.60948 19.0243 5.1433C18.5582 4.67713 17.9642 4.35966 17.3176 4.23104C16.671 4.10243 16.0008 4.16844 15.3917 4.42073C14.7826 4.67302 14.262 5.10026 13.8958 5.64843C13.5295 6.19659 13.334 6.84106 13.334 7.50033C13.335 8.38408 13.6865 9.23135 14.3114 9.85625C14.9363 10.4812 15.7836 10.8327 16.6673 10.8337ZM16.6673 5.83366C16.997 5.83366 17.3192 5.93141 17.5933 6.11454C17.8674 6.29768 18.081 6.55798 18.2071 6.86252C18.3333 7.16707 18.3663 7.50218 18.302 7.82548C18.2377 8.14878 18.0789 8.44575 17.8458 8.67884C17.6127 8.91193 17.3158 9.07066 16.9925 9.13497C16.6692 9.19928 16.3341 9.16627 16.0295 9.04013C15.725 8.91398 15.4647 8.70036 15.2815 8.42628C15.0984 8.1522 15.0007 7.82996 15.0007 7.50033C15.0012 7.05845 15.1769 6.63482 15.4894 6.32237C15.8018 6.00992 16.2254 5.83417 16.6673 5.83366Z" />
    </svg>
  )
}
export default Index;
