import React from 'react'
import styled from 'styled-components'

import ArrowBtnIcon from 'assets/icons/ArrowBtn';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  svg {
    transform: ${props => props.previous ? 'rotate(180deg)' : 'unset'};
    margin-right: 5px;
  }
`
const SecondaryBtn = ({ children, previous, onClick }) => {
  if (onClick) return (
    <StyledButton previous={previous} type="button" onClick={() => onClick()} >
      <ArrowBtnIcon />
      {children}
    </StyledButton >
  )
  return (
    <StyledButton previous={previous} type="button" >
      <ArrowBtnIcon />
      {children}
    </StyledButton >
  )
}

export default SecondaryBtn
