import React from 'react';

function Index() {
  return (
    <svg viewBox="0 0 44 50" fill="#C91200" xmlns="http://www.w3.org/2000/svg">
      <path d="M43.5781 36.8954C43.4145 36.7466 43.1945 36.6739 42.9746 36.6966L35.4239 37.4519L23.9139 29.8746C23.8575 29.8371 23.7957 29.8073 23.7315 29.7862L21.3832 29.0034C21.1436 28.9243 20.8822 28.9635 20.6779 29.1114C20.4728 29.2578 20.3523 29.4942 20.3523 29.7455V32.8766C20.3523 33.023 20.393 33.1654 20.4697 33.2899L23.9616 38.9087L20.9213 40.7044H0.782779C0.349902 40.7044 0 41.0551 0 41.4872V43.7995C0 44.1596 0.245793 44.4735 0.595695 44.5596L16.2513 48.4132C16.3115 48.4281 16.3726 48.4351 16.4344 48.4359L35.1499 48.5322L42.908 49.9866C42.9566 49.9952 43.0043 49.9999 43.0528 49.9999C43.2337 49.9999 43.4114 49.9365 43.553 49.8198C43.7323 49.6711 43.8356 49.4504 43.8356 49.2171V37.4754C43.8356 37.2539 43.7425 37.0433 43.5781 36.8954ZM42.2701 48.2738L35.3699 46.9799C35.3229 46.9713 35.2759 46.9666 35.229 46.9666L16.5354 46.8703L1.56556 43.1858V42.2699H21.135C21.2751 42.2699 21.4121 42.2324 21.5335 42.1611L25.4474 39.8488C25.6282 39.7416 25.7589 39.5662 25.809 39.3611C25.8591 39.1568 25.8254 38.94 25.7143 38.7615L21.9178 32.6535V30.8312L23.1389 31.2382L34.4423 38.6801V43.8355H36.0078V38.9666L42.2701 38.3404V48.2738Z" />
      <path d="M36.7909 36.0079V24.2662C36.7909 23.8341 36.441 23.4834 36.0082 23.4834H26.6148C26.1819 23.4834 25.832 23.8341 25.832 24.2662V29.7456H27.3976V25.049H35.2254V36.0079H36.7909Z" />
      <path d="M7.8277 16.4384C7.39482 16.4384 7.04492 16.789 7.04492 17.2211V28.9628H8.61048V18.0039H16.4383V23.4834H18.0038V13.3072H25.8316V18.7867H27.3972V12.5245C27.3972 12.0924 27.0473 11.7417 26.6144 11.7417H22.7005V7.82779H31.3111C32.6058 7.82779 33.6594 6.77417 33.6594 5.47945V0.782779C33.6594 0.350685 33.3095 0 32.8766 0H21.9177C21.4848 0 21.1349 0.350685 21.1349 0.782779V3.13112V7.04501V11.7417H17.221C16.7882 11.7417 16.4383 12.0924 16.4383 12.5245V16.4384H7.8277ZM22.7005 1.56556H32.0938V5.47945C32.0938 5.91076 31.7432 6.26223 31.3111 6.26223H22.7005V3.13112V1.56556Z" />
      <path d="M18.0031 31.311H16.4375V39.1388H18.0031V31.311Z" />
      <path d="M4.69642 9.39355H3.13086V10.9591H4.69642V9.39355Z" />
      <path d="M7.82728 9.39355H6.26172V10.9591H7.82728V9.39355Z" />
      <path d="M6.26282 7.82812H4.69727V9.39368H6.26282V7.82812Z" />
      <path d="M10.9581 4.69678H9.39258V6.26234H10.9581V4.69678Z" />
      <path d="M6.26282 10.959H4.69727V12.5245H6.26282V10.959Z" />
      <path d="M13.9061 31.8169L22.2819 21.9179H32.0948C32.3273 21.9179 32.5472 21.8146 32.6967 21.6361L39.1398 13.9038V15.6557H40.7053V11.7418C40.7053 11.3097 40.3554 10.959 39.9226 10.959H36.0087V12.5245H38.2513L31.7284 20.3523H21.9187C21.6885 20.3523 21.4701 20.4533 21.3206 20.6294L12.9449 30.5285H1.56641V32.094H13.3081C13.5382 32.094 13.7566 31.993 13.9061 31.8169Z" />
      <path d="M8.61048 33.6592H7.04492V39.1386H8.61048V33.6592Z" />
    </svg>

  )
}
export default Index;
