import React, { useState } from 'react';
import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const AgenciesMap = ({ selectedAgency, defaultAgency }) => {

  const [agency, setAgency] = useState(defaultAgency)

  const selectAgency = (agency) => {
    setAgency(agency)
    selectedAgency(agency)
  }

  return (
    <StyledContainer>
      <svg width="251" height="250" viewBox="0 0 251 250" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M96.5218 57.8346C97.0681 57.381 97.3963 56.722 97.4264 56.0103L97.8449 46.36L121.744 34.8705C122.455 34.5271 122.964 33.8731 123.117 33.0963L125.109 23.0025C125.377 21.6468 124.493 20.3287 123.137 20.058C121.776 19.7974 120.461 20.6745 120.193 22.0327L118.441 30.8961L94.3216 42.4934C93.4871 42.8944 92.9433 43.7188 92.9032 44.6435L92.5374 53.0233L72.9412 50.1791L67.2452 43.17C66.3732 42.095 64.7944 41.9346 63.7219 42.8042L55.9511 49.114C55.1818 49.738 54.8535 50.7579 55.1116 51.7152L60.6923 72.3037L42.0308 75.0828L34.6759 66.4349C34.0119 65.6505 32.9343 65.3599 31.962 65.6831L1.85854 75.8797C1.1619 76.1177 0.603078 76.6465 0.33244 77.3306C0.061802 78.0122 0.104402 78.784 0.450218 79.433L12.1027 101.282C12.3383 101.723 12.6991 102.081 13.1401 102.315L49.8743 121.813L68.0772 154.548L62.8799 213.614L56.1892 220.754C55.6579 221.323 55.4173 222.104 55.5451 222.874C55.6729 223.643 56.1491 224.307 56.8357 224.678L84.0273 239.245C84.3907 239.438 84.7991 239.541 85.2101 239.541H121.628C123.012 239.541 124.134 238.42 124.134 237.035C124.134 235.649 123.012 234.529 121.628 234.529H85.8391L62.0806 221.799L67.1325 216.411C67.5159 216.003 67.7514 215.476 67.8015 214.918L73.1416 154.222C73.1867 153.723 73.079 153.222 72.8359 152.784L53.8988 118.731C53.6632 118.31 53.3124 117.961 52.8839 117.738L16.1723 98.2499L6.26894 79.6836L31.9645 70.9781L39.1189 79.3904C39.6802 80.052 40.5372 80.3703 41.3968 80.2475L64.2206 76.8469C64.9273 76.7417 65.5562 76.3382 65.9472 75.7393C66.3381 75.1429 66.4559 74.4037 66.2679 73.712L60.3765 51.9783L64.9373 48.2746L69.671 54.0983C70.0669 54.5844 70.6332 54.9052 71.2547 54.9954L94.5621 58.3784C95.2588 58.4912 95.9755 58.2832 96.5218 57.8346Z" fill="#364350" />
        <path d="M248.773 63.2895L209.265 48.1789L190.326 42.498L165.467 21.9395C165.374 21.8643 165.276 21.7916 165.174 21.7315L146.194 10.1542L144.47 1.98743C144.315 1.26323 143.848 0.641762 143.194 0.293441C142.54 -0.0548806 141.766 -0.0949768 141.074 0.183179L124.322 6.97921C123.039 7.50044 122.423 8.96138 122.942 10.2444C123.46 11.5274 124.921 12.1439 126.207 11.6252L140.182 5.95429L141.51 12.2491C141.653 12.9257 142.069 13.5096 142.658 13.8679L162.41 25.9164L187.521 46.6828C187.782 46.8958 188.08 47.0562 188.401 47.1539L207.651 52.92L244.827 67.1461L234.605 102.241L231.703 103.692C231.312 103.888 230.976 104.183 230.733 104.549L204.998 143.396C204.386 144.321 204.454 145.538 205.166 146.39C205.88 147.24 207.07 147.523 208.082 147.08L220.061 141.887L227.491 166.646L219.179 172.88C218.234 173.59 217.913 174.865 218.409 175.938L230.548 202.157C230.971 203.072 231.876 203.61 232.823 203.61C233.177 203.61 233.532 203.538 233.873 203.377C235.129 202.798 235.677 201.307 235.094 200.052L223.807 175.672L231.899 169.603C232.738 168.971 233.096 167.884 232.793 166.879L224.053 137.745C223.847 137.061 223.358 136.495 222.709 136.191C222.058 135.893 221.311 135.883 220.657 136.164L213.841 139.118L234.542 107.875L237.825 106.233C238.449 105.92 238.915 105.361 239.111 104.692L250.284 66.3292C250.648 65.0787 249.991 63.7556 248.773 63.2895Z" fill="#364350" />
        <path d="M231.6 212.032L209.227 224.617L170.438 212.789C169.709 212.566 168.917 212.686 168.291 213.117L151.293 224.772C150.612 225.241 150.203 226.013 150.203 226.84V244.241H128.918C127.535 244.241 126.412 245.361 126.412 246.747C126.412 248.132 127.535 249.253 128.918 249.253H152.709C154.095 249.253 155.215 248.132 155.215 246.747V228.158L170.13 217.931L208.791 229.719C209.453 229.922 210.155 229.847 210.751 229.508L234.058 216.398C235.264 215.718 235.692 214.192 235.013 212.987C234.334 211.782 232.806 211.353 231.6 212.032Z" fill="#364350" />
        <StyledCircle isSelected={agency === 'Toulouse'} onClick={() => selectAgency('Toulouse')} cx="117.812" cy="198.377" r="5.77444" fill="#364350" />
        <StyledCircle isSelected={agency === 'Montpellier'} onClick={() => selectAgency('Montpellier')} cx="163.774" cy="197.774" r="5.77444" fill="#364350" />
        <StyledCircle isSelected={agency === 'Paris'} onClick={() => selectAgency('Paris')} cx="140.909" cy="86.7378" r="5.77444" fill="#364350" />
      </svg>
    </StyledContainer>
  )
}

const ActiveAgencyStyle = css`
  fill: ${props => props.theme.colors.brand};
  r: 10px;
`
const StyledContainer = styled.div`
  margin-top: 20px;
  ${breakpoint('sm')`
    position: relative;
    right: 20px;
  `}
`
const StyledCircle = styled.circle`
  transition: all .1s ${props => props.theme.baseCubicBezier};
cursor: pointer;
  &:hover {
   ${ActiveAgencyStyle}
}
  ${props => props.isSelected ? ActiveAgencyStyle : null}
`

export default AgenciesMap
