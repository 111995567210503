import React from 'react'

export default function crossBtn({ className }) {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="2.12132" y1="2.00024" x2="17.336" y2="17.215" stroke="#364350" strokeWidth="3" strokeLinecap="round" />
      <line x1="2.66406" y1="17.2146" x2="17.8788" y2="1.9999" stroke="#364350" strokeWidth="3" strokeLinecap="round" />
    </svg>
  )
}

