import React from 'react'
import styled from 'styled-components'
import Heading from 'components/global/Heading'
import Button from 'components/global/Button';
import MailBox from 'assets/icons/MailBox';

const FormFeedback = ({ callBackQuit }) => {
  return (
    <StyledContainer>
      <StyledHeading section>
        Merci pour votre<br />message !
      </StyledHeading>
      <p>
        Nous vous répondront d’ici les plus brefs délais.
      </p>
      <MailBox />
      <Button onClick={() => callBackQuit()}>revenir au site</Button>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    margin: 50px 0;
  }
`
const StyledHeading = styled(Heading)`
  margin: 0 0 30px 0;  
  text-align: center;
`

export default FormFeedback
