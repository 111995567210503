import React from 'react';

function ArrowBtn({ className }) {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 20C11.9778 20 13.9112 19.4135 15.5557 18.3147C17.2002 17.2159 18.4819 15.6541 19.2388 13.8268C19.9957 11.9996 20.1937 9.98891 19.8078 8.0491C19.422 6.10929 18.4696 4.32746 17.0711 2.92893C15.6725 1.53041 13.8907 0.577999 11.9509 0.192146C10.0111 -0.193707 8.00042 0.00432777 6.17316 0.761204C4.3459 1.51808 2.78411 2.79981 1.6853 4.4443C0.586487 6.08879 -3.8147e-06 8.02219 -3.8147e-06 10C-3.8147e-06 11.3132 0.258654 12.6136 0.7612 13.8268C1.26375 15.0401 2.00034 16.1425 2.92893 17.0711C3.85751 17.9997 4.9599 18.7362 6.17316 19.2388C7.38642 19.7413 8.68678 20 10 20ZM6 9H11.59L10.3 7.71C10.1362 7.5187 10.0506 7.27262 10.0603 7.02095C10.07 6.76927 10.1743 6.53053 10.3524 6.35244C10.5305 6.17434 10.7693 6.07001 11.0209 6.06029C11.2726 6.05057 11.5187 6.13617 11.71 6.3L14.71 9.3C14.8962 9.48736 15.0008 9.74081 15.0008 10.005C15.0008 10.2692 14.8962 10.5226 14.71 10.71L11.71 13.71C11.6203 13.8147 11.51 13.8997 11.386 13.9597C11.2619 14.0197 11.1268 14.0535 10.989 14.0588C10.8513 14.0641 10.714 14.0409 10.5857 13.9906C10.4573 13.9404 10.3408 13.8641 10.2433 13.7667C10.1459 13.6692 10.0696 13.5526 10.0194 13.4243C9.96908 13.296 9.94588 13.1587 9.9512 13.0209C9.95652 12.8832 9.99025 12.7481 10.0503 12.624C10.1103 12.5 10.1953 12.3896 10.3 12.3L11.59 11H6C5.73478 11 5.48042 10.8946 5.29289 10.7071C5.10535 10.5196 5 10.2652 5 10C5 9.73478 5.10535 9.48043 5.29289 9.29289C5.48042 9.10536 5.73478 9 6 9Z" fill="#364350" />
    </svg>
  )
}

export default ArrowBtn;

