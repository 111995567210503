import React from 'react'

export default function SolutionITIcon() {
  return (
    <svg viewBox="0 0 64 62" fill="#C91200" xmlns="http://www.w3.org/2000/svg">
      <path d="M30 34.4479H28V36.3591H30V34.4479Z" />
      <path d="M30 38.2703H28V40.1815H30V38.2703Z" />
      <path d="M34 34.4479H32V36.3591H34V34.4479Z" />
      <path d="M34 38.2703H32V40.1815H34V38.2703Z" />
      <path d="M38 34.4479H36V36.3591H38V34.4479Z" />
      <path d="M38 38.2703H36V40.1815H38V38.2703Z" />
      <path d="M42 34.4479H40V36.3591H42V34.4479Z" />
      <path d="M42 38.2703H40V40.1815H42V38.2703Z" />
      <path d="M46 34.4479H44V36.3591H46V34.4479Z" />
      <path d="M46 38.2703H44V40.1815H46V38.2703Z" />
      <path d="M24 34.4479H20C18.897 34.4479 18 35.305 18 36.3591V38.2703C18 39.3243 18.897 40.1815 20 40.1815H24C25.103 40.1815 26 39.3243 26 38.2703V36.3591C26 35.305 25.103 34.4479 24 34.4479ZM20 38.2703V36.3591H24L24.002 38.2703H20Z" />
      <path d="M48 0.0463867H16C14.897 0.0463867 14 0.903558 14 1.95758V11.5136C14 12.5676 14.897 13.4248 16 13.4248V15.336C14.897 15.336 14 16.1931 14 17.2472V26.8031C14 27.8572 14.897 28.7143 16 28.7143V30.6255C14.897 30.6255 14 31.4827 14 32.5367V42.0927C14 43.1467 14.897 44.0039 16 44.0039V45.9151C14.897 45.9151 14 46.7723 14 47.8263V57.3823C14 58.4363 14.897 59.2935 16 59.2935V61.2047H18V59.2935H46V61.2047H48V59.2935C49.103 59.2935 50 58.4363 50 57.3823V47.8263C50 46.7723 49.103 45.9151 48 45.9151V44.0039C49.103 44.0039 50 43.1467 50 42.0927V32.5367C50 31.4827 49.103 30.6255 48 30.6255V28.7143C49.103 28.7143 50 27.8572 50 26.8031V17.2472C50 16.1931 49.103 15.336 48 15.336V13.4248C49.103 13.4248 50 12.5676 50 11.5136V1.95758C50 0.903558 49.103 0.0463867 48 0.0463867ZM48.002 57.3823H16V47.8263H17H47H48L48.002 57.3823ZM48 42.0927H47H44H42V44.0039H44H46V45.9151H18V44.0039H20H40V42.0927H20H17H16V32.5367H17H47H48V42.0927ZM46 30.6255H18V28.7143H46V30.6255ZM48.002 26.8031H47H17H16V17.2472H17H47H48L48.002 26.8031ZM46 15.336H18V13.4248H46V15.336ZM47 11.5136H17H16V1.95758H48L48.002 11.5136H47Z" />
      <path d="M30 3.86877H28V5.77997H30V3.86877Z" />
      <path d="M30 7.69116H28V9.60236H30V7.69116Z" />
      <path d="M34 3.86877H32V5.77997H34V3.86877Z" />
      <path d="M34 7.69116H32V9.60236H34V7.69116Z" />
      <path d="M38 3.86877H36V5.77997H38V3.86877Z" />
      <path d="M38 7.69116H36V9.60236H38V7.69116Z" />
      <path d="M42 3.86877H40V5.77997H42V3.86877Z" />
      <path d="M42 7.69116H40V9.60236H42V7.69116Z" />
      <path d="M46 3.86877H44V5.77997H46V3.86877Z" />
      <path d="M46 7.69116H44V9.60236H46V7.69116Z" />
      <path d="M24 3.86877H20C18.897 3.86877 18 4.72595 18 5.77997V7.69117C18 8.74519 18.897 9.60236 20 9.60236H24C25.103 9.60236 26 8.74519 26 7.69117V5.77997C26 4.72595 25.103 3.86877 24 3.86877ZM20 7.69117V5.77997H24L24.002 7.69117H20Z" />
      <path d="M30 49.7375H28V51.6488H30V49.7375Z" />
      <path d="M30 53.5599H28V55.4711H30V53.5599Z" />
      <path d="M38 49.7375H36V51.6488H38V49.7375Z" />
      <path d="M38 53.5599H36V55.4711H38V53.5599Z" />
      <path d="M46 49.7375H44V51.6488H46V49.7375Z" />
      <path d="M46 53.5599H44V55.4711H46V53.5599Z" />
      <path d="M24 49.7375H20C18.897 49.7375 18 50.5947 18 51.6487V53.5599C18 54.614 18.897 55.4711 20 55.4711H24C25.103 55.4711 26 54.614 26 53.5599V51.6487C26 50.5947 25.103 49.7375 24 49.7375ZM20 53.5599V51.6487H24L24.002 53.5599H20Z" />
      <path d="M34 49.7375H32V55.4711H34V49.7375Z" />
      <path d="M42 49.7375H40V55.4711H42V49.7375Z" />
      <path d="M30 19.1583H28V21.0695H30V19.1583Z" />
      <path d="M30 22.9807H28V24.8919H30V22.9807Z" />
      <path d="M38 19.1583H36V21.0695H38V19.1583Z" />
      <path d="M38 22.9807H36V24.8919H38V22.9807Z" />
      <path d="M46 19.1583H44V21.0695H46V19.1583Z" />
      <path d="M46 22.9807H44V24.8919H46V22.9807Z" />
      <path d="M24 19.1583H20C18.897 19.1583 18 20.0155 18 21.0695V22.9807C18 24.0347 18.897 24.8919 20 24.8919H24C25.103 24.8919 26 24.0347 26 22.9807V21.0695C26 20.0155 25.103 19.1583 24 19.1583ZM20 22.9807V21.0695H24L24.002 22.9807H20Z" />
      <path d="M34 19.1583H32V24.8919H34V19.1583Z" />
      <path d="M42 19.1583H40V24.8919H42V19.1583Z" />
      <path d="M61 27.7588C59.698 27.7588 58.598 28.5605 58.184 29.67H52V31.5812H58.184C58.598 32.6906 59.698 33.4924 61 33.4924C62.654 33.4924 64 32.2061 64 30.6256C64 29.045 62.654 27.7588 61 27.7588ZM61 31.5812C60.448 31.5812 60 31.1531 60 30.6256C60 30.0981 60.448 29.67 61 29.67C61.552 29.67 62 30.0981 62 30.6256C62 31.1531 61.552 31.5812 61 31.5812Z" />
      <path d="M60 21.0695V10.3821C61.161 9.98648 62 8.93532 62 7.69114C62 6.11058 60.654 4.82434 59 4.82434C57.346 4.82434 56 6.11058 56 7.69114C56 8.93532 56.839 9.98648 58 10.3821V20.1139H52V22.0251H59C59.553 22.0251 60 21.598 60 21.0695ZM59 6.73554C59.552 6.73554 60 7.16365 60 7.69114C60 8.21863 59.552 8.64673 59 8.64673C58.448 8.64673 58 8.21863 58 7.69114C58 7.16365 58.448 6.73554 59 6.73554Z" />
      <path d="M60 50.869V40.1816C60 39.6531 59.553 39.226 59 39.226H52V41.1371H58V50.869C56.839 51.2646 56 52.3157 56 53.5599C56 55.1405 57.346 56.4267 59 56.4267C60.654 56.4267 62 55.1405 62 53.5599C62 52.3157 61.161 51.2646 60 50.869ZM59 54.5155C58.448 54.5155 58 54.0874 58 53.5599C58 53.0324 58.448 52.6043 59 52.6043C59.552 52.6043 60 53.0324 60 53.5599C60 54.0874 59.552 54.5155 59 54.5155Z" />
      <path d="M3 27.7588C1.346 27.7588 0 29.045 0 30.6256C0 32.2061 1.346 33.4924 3 33.4924C4.302 33.4924 5.402 32.6906 5.816 31.5812H12V29.67H5.816C5.402 28.5605 4.302 27.7588 3 27.7588ZM3 31.5812C2.448 31.5812 2 31.1531 2 30.6256C2 30.0981 2.448 29.67 3 29.67C3.552 29.67 4 30.0981 4 30.6256C4 31.1531 3.552 31.5812 3 31.5812Z" />
      <path d="M6 10.3821C7.161 9.98648 8 8.93532 8 7.69114C8 6.11058 6.654 4.82434 5 4.82434C3.346 4.82434 2 6.11058 2 7.69114C2 8.93532 2.839 9.98648 4 10.3821V21.0695C4 21.598 4.447 22.0251 5 22.0251H12V20.1139H6V10.3821ZM5 6.73554C5.552 6.73554 6 7.16365 6 7.69114C6 8.21863 5.552 8.64673 5 8.64673C4.448 8.64673 4 8.21863 4 7.69114C4 7.16365 4.448 6.73554 5 6.73554Z" />
      <path d="M4 40.1816V50.869C2.839 51.2646 2 52.3157 2 53.5599C2 55.1405 3.346 56.4267 5 56.4267C6.654 56.4267 8 55.1405 8 53.5599C8 52.3157 7.161 51.2646 6 50.869V41.1371H12V39.226H5C4.447 39.226 4 39.6531 4 40.1816ZM5 54.5155C4.448 54.5155 4 54.0874 4 53.5599C4 53.0324 4.448 52.6043 5 52.6043C5.552 52.6043 6 53.0324 6 53.5599C6 54.0874 5.552 54.5155 5 54.5155Z" />
    </svg>
  )
}
