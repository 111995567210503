import React from 'react';
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const Index = ({ children, className }) => {
  return (
    <StyledContainer className={className}>
      {children}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  margin: auto;
  max-width: ${props => props.theme.layoutWidth.xs};
  padding: 0 15px;
  ${breakpoint('sm')`
    max-width: ${props => props.theme.layoutWidth.sm};
  `}
  ${breakpoint('md')`
    max-width: ${props => props.theme.layoutWidth.md};
  `}
  ${breakpoint('lg')`
    max-width: ${props => props.theme.layoutWidth.lg};
  `}
  ${breakpoint('xl')`
    max-width: ${props => props.theme.layoutWidth.xl};
  `}
  ${breakpoint('xxl')`
    max-width: ${props => props.theme.layoutWidth.xxl};
  `}
`

export default Index;
