import React from 'react';
import styled from 'styled-components'
import { Link } from 'gatsby'
import breakpoint from 'styled-components-breakpoint';

import expertisesData from 'data/expertises'
import ContactSection from './ContactSection';
import Container from 'components/global/Container'
import LogoLMIWhite from 'assets/logos/LogoLMIWhite'

const Index = ({ toggleModal }) => {

  const expertisesLinks = expertisesData.map(expertise => {
    return (
      <li key={expertise.name}>
        <Link to="/expertises" state={{ expertiseLinked: expertise.name }} >{expertise.name}</Link>
      </li>
    )
  })

  return (
    <>
      <ContactSection toggleModal={() => toggleModal()} />
      <StyledSectionContainer>
        <StyledContainer>
          <LogoLMIWhite />
          <StyledCol>
            <h2>Entreprise</h2>
            <ul>
              <li><Link to="/a-propos-de-nous" >À propos de nous</Link></li>
              <li><Link to="/expertises" >Expertises</Link></li>
              <li><Link to="/projets" >Projets</Link></li>
              <li><Link to="/actus" >Actus</Link></li>
            </ul>
          </StyledCol>
          <StyledCol>
            <h2>Expertises</h2>
            <ul>
              {expertisesLinks}
            </ul>
          </StyledCol>
          <StyledCol>
            <h2>Ressources</h2>
            <ul>
              <li>
                <a href="https://fr.linkedin.com/company/lm-ingenierie" target="_blank" rel="noreferrer">LinkedIn</a>
              </li>
              <li><Link to="/mentions-legales" >Mentions légales</Link></li>
            </ul>
          </StyledCol>
        </StyledContainer>
      </StyledSectionContainer>
    </>
  );
}

const StyledSectionContainer = styled.section`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.black};
  padding: 90px 0;
`
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${breakpoint('lg')`
    flex-direction: row;
  `}
  & > *:not(:last-child) {
  margin-bottom: 30px;
    ${breakpoint('lg')`
    `}
  }
`
const StyledCol = styled.div`
  h2 {
    font-weight: 700;
  }
  h2, li {
    margin-bottom: 10px;
  }
  a {
    color: ${props => props.theme.colors.white};
  }
`

export default Index;
