import React, { useState } from 'react';
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';
import { Link } from 'gatsby'

import Container from 'components/global/Container'
import Button from 'components/global/Button';
import LogoLMI from 'assets/logos/LogoLMI';
import ToggleBtn from './ToggleBtn';

const StyledNav = styled.nav`
  display: flex;
  padding: 30px 0;
  flex-direction: column;
  ${breakpoint('lg')`
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  `}
`
const StyledLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.isNavOpen ? '1' : '0'};
  max-height: ${props => props.isNavOpen ? '290px' : '0px'};
  transition: all .4s;
  overflow: hidden;
  padding: ${props => props.isNavOpen ? '10px 0 22px' : '0'};
  visibility: ${props => props.isNavOpen ? 'visible' : 'hidden'};
  ${breakpoint('lg')`
    overflow: visible;
    opacity: 1;
    padding: 0;
    flex-direction: row;
    display: flex;
    max-height: unset;
    visibility: visible;
  `}
  a {
    text-align: center;
    padding: 0 0 12px 0;
    margin: 12px 0 0;
    font-weight: 700;
    border-bottom: 1px solid ${props => props.theme.colors.grey};
    width: 100%;
    ${breakpoint('lg')`
      border-bottom: none;
      padding: 0;
      margin: 0 15px;
      width: unset;
    `}
  }
`
const StyledMobileButton = styled(Button)`
  flex-shrink: 0;
  margin: 20px auto 0;
  ${breakpoint('lg')`
    display: none;
  `}
`
const StyledDesktopButton = styled(Button)`
  display: none;
  ${breakpoint('lg')`
    display: block;
  `}
`
const StyledMobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledLink = styled(Link)`
  position: relative;
  &.active, &:hover {
    color: ${props => props.theme.colors.brand};
    ${breakpoint('lg')`
      color: ${props => props.theme.colors.black};
    `}
    &::after {
      content: '';
      height: 2px;
      width: 100%;
      background-color: ${props => props.theme.colors.brand};
      border-radius: 2px;
      position: absolute;
      display: none;
      bottom: -2px;
      ${breakpoint('lg')`
        display: block;
      `}
    }
  }  
`

const Navigation = ({ toggleModal }) => {

  const [isNavOpen, setNavOpen] = useState(false)

  const toggleNav = () => {
    setNavOpen(!isNavOpen)
  }

  return (
    <Container>
      <StyledNav>
        <StyledMobileHeader isNavOpen={isNavOpen}>
          <Link aria-label="Page d'accueil" to="/" >
            <LogoLMI />
          </Link>
          <ToggleBtn onClick={toggleNav} isNavOpen={isNavOpen} />
        </StyledMobileHeader>
        <StyledLinksContainer isNavOpen={isNavOpen}>
          <StyledLink to="/a-propos-de-nous" activeClassName="active">
            À​ propos de nous
          </StyledLink>
          <StyledLink to="/expertises" activeClassName="active">
            Expertises
          </StyledLink>
          <StyledLink to="/projets" activeClassName="active">
            Projets
          </StyledLink>
          <StyledLink to="/actus" activeClassName="active">
            Actus
          </StyledLink>
          <StyledMobileButton onClick={() => toggleModal()} isNavOpen={isNavOpen}>
            Nous contacter
          </StyledMobileButton>
        </StyledLinksContainer>
        <StyledDesktopButton onClick={() => toggleModal()} isNavOpen={isNavOpen}>
          Nous contacter
        </StyledDesktopButton>
      </StyledNav>
    </Container >
  );
}

export default Navigation;
