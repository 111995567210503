import React, { useState } from 'react';
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Heading from 'components/global/Heading'
import Button from 'components/global/Button';
import SecondaryBtn from 'components/global/Button/SecondaryBtn';
import ArrowSelect from 'assets/icons/ArrowSelect';

const ContactSteps2 = ({ nextStep, prevStep, agency }) => {

  const initialState = {
    Email: "",
    Prénom: "",
    Nom: "",
    Expertises: "",
    Entreprise: "",
    Message: ""
  }

  const [formData, setFormData] = useState(initialState)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": event.target.getAttribute("name"),
        "subject": "[lm-ing.com] Nouvelle prise de contact",
        "Agence": agency,
        ...formData,
      })
    })
      .then((response) => {
        console.log(response)
        nextStep()
        setFormData({ ...initialState })
        console.log("formData =>", formData)
      })
      .catch(error => alert(error));
  };

  return (
    <StyledContainer>
      <StyledHeading section>
        Contacter notre agence<br /> de <span className='red'>{agency}</span>
      </StyledHeading>
      <p>Une question sur la façon dont nous pourrions collaborer ? Remplissez ce formulaire et nous reviendrons vers vous rapidement.</p>
      <StyledForm
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="subject" value="[lm-ing.com] Nouvelle prise de contact" />
        <input type="email" name="Email" placeholder='Email' onChange={handleChange} required />
        <input type="text" name="Prénom" placeholder='Prénom' onChange={handleChange} required />
        <input type="text" name="Nom" placeholder='Nom' onChange={handleChange} required />
        <StyledSelectContainer>
          <select name="Expertises" onChange={handleChange} required>
            <option value="Expertises">Expertises</option>
            <option value="Accompagnement au changement">Accompagnement au changement</option>
            <option value="Infrastructures">Infrastructures</option>
            <option value="IoT – Objets connectés">IoT – Objets connectés</option>
            <option value="SIG-Cartographie">SIG-Cartographie</option>
            <option value="Smart territoires – City – Building">Smart territoires – City – Building</option>
            <option value="Solution IT">Solution IT</option>
            <option value="Solution sureté & sécurité">Solution sureté & sécurité</option>
            <option value="Vidéo protection urbaine">Vidéo protection urbaine</option>
          </select>
          <ArrowSelect />
        </StyledSelectContainer>
        <input type="text" name="Entreprise" placeholder='Entreprise' onChange={handleChange} required />
        <textarea rows="4" name="Message" placeholder='Message' onChange={handleChange} required />
        <StyledDisclaimer>
          En envoyant ce formulaire, j'autorise LM Ingénierie a utiliser ces informations pour me contacter et répondre à ma demande formulée. LM Ingénierie s'engage à ne pas divulguer ces informations. Je prends également connaissance de la politique de confidentialité du site.
        </StyledDisclaimer>
        <StyledBtnsContainer>
          <SecondaryBtn previous onClick={() => prevStep()}>précédent</SecondaryBtn>
          <Button type="submit">Envoyer</Button>
        </StyledBtnsContainer>
      </StyledForm>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledHeading = styled(Heading)`
  margin: 0 0 30px 0;  
  text-align: center;
  br {
    display: none;
    ${breakpoint('sm')`
      display: block;
    `}
  }
`
const StyledForm = styled.form`
  margin: 30px 0;
  width: 100%;
  input, select, textarea {
    background: ${props => props.theme.colors.grey};
    border: none;
    height: 50px;
    border-radius: 30px;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 15px;
    &::placeholder {
      color: ${props => props.theme.colors.black};
    }
  }
  select {
    color: ${props => props.theme.colors.black};
    appearance: none;
    cursor: pointer;
  }
  textarea {
    padding:20px;
    height: auto;
    resize: none;
  }
`
const StyledSelectContainer = styled.div`
  position: relative;
  svg {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`
const StyledBtnsContainer = styled.div`
  display: inline-grid;
  display: grid;
  grid-template-rows: 1fr; 
  margin-top: 30px;
  grid-template-columns: repeat(2, 1fr);
  ${breakpoint('md')`
    grid-template-columns: repeat(3, 1fr);
  `}
  button {
    &:first-child {
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
    }
    &:last-child {
      grid-area: 1 / 2 / 2 / 3;
      justify-self: center;
    }
  }
`

const StyledDisclaimer = styled.p`
  text-align: left;
  font-size: 12px;
  line-height: normal;
  padding: 0px 19px;
`

export default ContactSteps2
