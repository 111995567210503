import React, { useState } from 'react';
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import theme from 'styles/theme'
import GlobalStyle from 'styles/globalStyle'
import Navigation from './Navigation';
import Footer from './Footer';
import ContactModal from './ContactModal';

const Layout = ({ children }) => {

  const [isModalOpen, setModalOpen] = useState(false)

  const toggleModal = () => {
    setModalOpen(!isModalOpen)
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <StyledBlurContainer isModalOpen={isModalOpen}>
          <Navigation toggleModal={toggleModal} />
          {children}
          <Footer toggleModal={toggleModal} />
        </StyledBlurContainer>
        <ContactModal isVisible={isModalOpen} callBackQuit={toggleModal} />
      </ThemeProvider>
    </>
  );
}

const StyledBlurContainer = styled.div`
  filter: ${props => props.isModalOpen ? 'blur(4px);' : 'none'};
  transition: filter .4s ${props => props.theme.baseCubicBezier};
`

export default Layout;
