import React, { useState } from 'react';
import Button from 'components/global/Button';
import styled from 'styled-components'

import Heading from 'components/global/Heading'
import AgenciesMap from './AgenciesMap'

const AgencyChoice = ({ nextStep, selectedAgency, defaultAgency }) => {

  const [agency, setAgency] = useState(defaultAgency)

  const callBackSelectedAgency = (agency) => {
    selectedAgency(agency)
    setAgency(agency)
  }

  return (
    <StyledContainer>
      <StyledHeading section>nous Contacter</StyledHeading>
      <p>Par téléphone au :</p>
      <StyledPhoneNumber href="tel:+33467506704">
        +33 4 67 50 67 04
      </StyledPhoneNumber>
      <p>Par message en choisissant une agence :</p>
      <AgenciesMap selectedAgency={callBackSelectedAgency} defaultAgency={defaultAgency} />
      <StyledAgencyName>{agency}</StyledAgencyName>
      <Button onClick={() => nextStep()}>Suivant</Button>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledHeading = styled(Heading)`
  margin: 0 0 30px 0;  
  text-align: center;
`

const StyledPhoneNumber = styled.a`
  color: ${props => props.theme.colors.brand};
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 50px;
  letter-spacing: 0.08em;
`
const StyledAgencyName = styled.p`
  color: ${props => props.theme.colors.brand};
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 50px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-top: 20px;
`

export default AgencyChoice
