import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

import Button from 'components/global/Button';
import Container from 'components/global/Container'

const Index = ({ toggleModal }) => {

  return (
    <StyledSection>
      <StaticImage
        style={{ gridArea: "1/1" }}
        layout="fullWidth"
        src="../../../../../assets/imgs/home/contact.jpg"
        alt="contact"
      />
      <StyledContent>
        <Container>
          <h2>Envie de collaborer avec nous&nbsp;?</h2>
          <p>Vous voulez en savoir plus sur nos solutions&nbsp;?<br />
            Prenez contact avec notre équipe.</p>
          <Button white onClick={() => toggleModal()} >nous contacter</Button>
        </Container>
      </StyledContent>
    </StyledSection>
  );
}

const StyledSection = styled.section`
  display: grid;
  height: 231px;
`
const StyledContent = styled.div`
  grid-area: 1/1;
  position: relative;
  place-items: center;
  display: grid;
  color: ${props => props.theme.colors.white};
  text-align: center;
  padding: 40px 0;
  align-self: start;
  h2 {
    font-size: 26px;
    padding-bottom: 12px;
    font-weight: 700;
  }
  p {
    padding-bottom: 25px;
  }
  button {
    margin: 0 auto;
  }
`

export default Index;
