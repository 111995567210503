import { createGlobalStyle } from 'styled-components/macro'
import 'typeface-nunito'

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
}

h1,
h2,
p,
a,
button,
div,
q,
input,
select,
textarea {
	font-size: 16px;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  line-height: 24px;
}

html, body {
  overflow-x: hidden;
}

body {
  color: ${props => props.theme.colors.black};
}

a {
  text-decoration: none;
  color: ${props => props.theme.colors.black};
  display: block;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

li {
  list-style: none;
}

.red {
  color: ${props => props.theme.colors.brand};
}
`
