import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import SmartTerritoiresIcon from "assets/icons/expertises/SmartTerritoiresIcon";
import IoTIcon from "assets/icons/expertises/IoTIcon";
import SolutionITIcon from "assets/icons/expertises/SolutionITIcon";
import CartographyIcon from "assets/icons/expertises/CartographyIcon";
import ChangeIcon from "assets/icons/expertises/ChangeIcon";
import InfrastructureIcon from "assets/icons/expertises/InfrastructureIcon";
import SolutionsSecurityIcon from "assets/icons/expertises/SolutionsSecurityIcon";
import VideoProtectionIcon from "assets/icons/expertises/VideoProtectionIcon";
import video from "src/assets/videos/Lizmap-videoprotection.mp4";

const expertises = [
  {
    name: "Smart territoires – City – Building",
    homePageDescription:
      "Afin de gérer et maitriser la révolution du Digital, nous vous proposons de définir ensemble la stratégie du numérique et la mise en oeuvre concrète de vos projets aux seins de vos territoires et bâtiments.",
    introParagraph: "Le monde s’urbanise de plus en plus.",
    description: [
      <p key="0">
        Pour répondre à cette mutation, les territoires, les métropoles, villes,
        quartiers et bâtiments se doivent de devenir plus intelligents, plus
        fluides, plus sûrs, plus vertueux.
      </p>,
      <p key="1">
        La révolution numérique avec l’avènement du digital et l’implantation
        d’infrastructure IT au sens large vont nourrir cette mutation.
      </p>,
      <p key="2">
        Les usages de plus en plus nombreux et gourmands en informations ou en
        données collectées par les objets connectés (IoT) vont pousser les
        territoires à repenser leur mode organisationnel.
      </p>,
      <p key="3">
        Pour faire face à cette évolution, et répondre aux nouvelles attentes
        des usagers, nous vous proposons de mettre à votre disposition nos
        compétences et notre approche transversale en adressant tous les
        secteurs et les enjeux du projet.
      </p>,
    ],
    domains: [
      "Ville 2.0",
      "Écoquartiers",
      "Éclairage Intelligent",
      "Efficacité énergétique",
      "Smart Grid",
      "Smart Building & Smart Home",
      "Smart water & Smart Metering",
      "Gestion intelligente des déchets",
      "Mobilité urbaine",
      "Open Data",
      "Plateforme",
      "Économie Collaborative",
      "Logistique urbaine",
      "gestion des compétences",
    ],
    icon: <SmartTerritoiresIcon />,
    img: (
      <StaticImage
        src="../assets/imgs/expertises/smart-territoires-city-building.jpg"
        height={770}
        placeholder="blurred"
        alt="Smart territoires – City – Building"
        style={{ borderRadius: "20px" }}
      />
    ),
  },

  {
    name: "IoT – Objets connectés",
    icon: <IoTIcon />,
    homePageDescription:
      "Notre valeur réside avant tout dans l’ensemble des services que nous portons, depuis l’étude de faisabilité terrain jusqu’à la mise en exploitation de votre infrastructure LPWan.",
    introParagraph:
      "L’internet des objets (IoT) comprend une grande variété d’objets (logiciels, capteurs, connectivités réseaux, ..) permettant de collecter et de partager l’information. L’IoT permet de réaliser de grandes choses (ex: connaître l’état des actifs en temps réel…).",
    description: [
      <p key="0">
        Tous ces appareils connectés et les données qu’ils génèrent ouvrent le
        champs des possibles pour les organisations, quelle que soit leur
        activité ou leur taille.
      </p>,
      <p key="1">
        Le développement de l’internet des objets(IoT) présente un potentiel de
        croissance formidable dans tous les secteurs.Les spécialistes parlent
        d’un monde en mutation profonde et cette transformation vient donc de
        commencer.
      </p>,
      <p key="2">
        LM Ingénierie peux vous accompagner dans cette mutation depuis l’étude
        de faisabilité(site Survey, informations de sursol/ modèle numérique de
        terrain et surface, cartographie, connexion des trackers, validation des
        applications, sécurisation des données, agrégation vers un dispositif
        centralisé), vous expliquer comment utiliser le big data et l’IoT de
        façon stratégique et comment la technologie peut vous accompagner dans
        vos processus et projets futurs.
      </p>,
    ],
    domains: [
      "Internet des objets",
      "Objets industriels connectés",
      "Réseaux LPWAN",
      "Capteurs sans fil",
      "Compteurs connectés",
    ],
    img: (
      <StaticImage
        src="../assets/imgs/expertises/iot-objets-connectes.jpg"
        height={770}
        placeholder="blurred"
        alt="IoT - Objets connectés"
        style={{ borderRadius: "20px" }}
      />
    ),
  },

  {
    name: "Solution IT",
    icon: <SolutionITIcon />,
    homePageDescription:
      "L’expertise et l’expérience de nos consultants, nous permettent de vous accompagner sur l’ensemble du périmètre IT de vos projets",
    introParagraph:
      "Répondre à divers enjeux majeurs, favoriser le travail collaboratif au sein de l’entreprise, accroître la réactivité et la communication entre les collaborateurs pour améliorer la productivité de l’entreprise.",
    description: [
      <p key="0">
        Tout ceci sont nos propositions de valeurs sur lesquelles nous sommes à
        même de vous accompagner et ainsi anticiper les moyens à mettre en
        oeuvre pour répondre à ces besoins/attentes avec l’objectif d’adresser
        les nouveaux enjeux auxquels doit faire face la DSI d’aujourd’hui.
      </p>,
      <p key="1">
        Un audit de vos besoins, une étude préparatoire de votre environnement,
        un accompagnement à la collecte des données, nos équipes vous
        accompagneront sereinement dans l’organisation et la planification de
        votre projet.
      </p>,
    ],
    domains: [
      "infrastructures Lan",
      "Man",
      "Wan",
      "Wi-Fi",
      "Li-Fi",
      "Communications unifiées",
      "usages collaboratifs",
      "Web et visioconférence",
      "IPTV",
      "aménagement de salle informatique",
      "DataCenter",
      "virtualisation",
    ],
    img: (
      <StaticImage
        src="../assets/imgs/expertises/solution-it.jpg"
        height={770}
        placeholder="blurred"
        alt="Solution IT"
        style={{ borderRadius: "20px" }}
      />
    ),
  },

  {
    name: "Vidéo protection urbaine",
    icon: <VideoProtectionIcon />,
    homePageDescription:
      "LM Ingénierie, fort d’une expérience reconnue dans les systèmes de vidéoprotection Urbaines, peut vous accompagner sur l’ensemble des phases de votre projet. Consultez nous !",
    introParagraph:
      "La Vidéoprotection urbaine est aujourd’hui un outil prépondérant pour accompagner les villes et territoires dans la prévention et sécurisation de leur périmètre.",
    description: [
      <p key="0">
        Le déploiement des réseaux Fibres au sein de nos territoires, offre des
        opportunités techniques et financières pour déployer un système de
        vidéoprotection fiable, sécurisé et évolutif.
      </p>,
      <p key="1">
        LM Ingénierie fort d’une expérience reconnue dans les systèmes de
        vidéoprotection Urbaines, peut vous accompagner sur l’ensemble des
        phases de votre projet.&nbsp;:
      </p>,
      <h3 key="2">Phase Avant-projet</h3>,
      <ul key="3">
        <li>Audit, visite des sites et évaluation des travaux.</li>
        <li>
          Aide à la réalisation des dossiers d’autorisation préfectorale et
          demande de subventions DETR et FIPD.
        </li>
      </ul>,
      <h3 key="4">Phase Avant-projet</h3>,
      <ul key="5">
        <li>Rédaction du DCE (dossier de consultation des entreprises)</li>
      </ul>,
      <h3 key="6">Phase Suivi des travaux et réception</h3>,
      <ul key="7">
        <li>Réception préalable et levée des réserves.</li>
        <li>Visa des DOE et PV de réception.</li>
      </ul>,
      <h3 key="8">Phase OPC (Phase transverse tout le long du projet)</h3>,
      <ul key="9">
        <li>Mise en place du calendrier prévisionnel et suivi.</li>
        <li>Organisation et coordination sur site des réunions.</li>
      </ul>,
      <h3 key="10">
        Avec la mise en place d’un outil d’aide à l’exploitation et gestion du
        système de vidéoprotection à étendre ou créer nous pourrons&nbsp;:
      </h3>,
      <ul key="11">
        <li>Définir le territoire&nbsp;: la zone à couvrir</li>
        <li>
          Identifier les réseaux existants disponibles (radio, WIFI, Fibre
          optique, cuivre,)
        </li>
        <li>Identifier le parc existant selon le cas</li>
        <li>
          Définir le type d’infrastructure à créer pour accueillir les nouvelles
          caméras
        </li>
        <li>Définir l’implantation des nouvelles caméras et leur adduction</li>
        <li>
          Créer la carte d’identité de la caméra à créer (caractéristiques
          techniques, types d’adduction, informations sur la maintenance,)
        </li>
        <li>Visualiser la zone de couverture</li>
        <li>
          Exporter ou visualiser pour l’exploitant l’`ensemble du système de
          vidéoprotection d’une caméra
        </li>
      </ul>,
      <h3 key="12">
        Ci-dessous en image un exemple de notre savoir-faire&nbsp;:
      </h3>,
      <video key="13" controls src={video} type="video/mov">
        <track kind="captions" />
      </video>,
    ],
    img: (
      <StaticImage
        src="../assets/imgs/expertises/video-protection-urbaine.jpg"
        height={770}
        placeholder="blurred"
        alt="Vidéo protection urbaine"
        style={{ borderRadius: "20px" }}
      />
    ),
  },

  {
    name: "Infrastructures",
    icon: <InfrastructureIcon />,
    homePageDescription:
      "Nous vous accompagnons dans l’étude et la définition des moyens permettant de mailler votre territoire. L’objectif ? Faciliter le déploiement de votre infrastructure",
    introParagraph:
      "Notre population de consultants dispose d’une capacité de réponse globale pour toute la chaîne de valeur du secteur des télécommunications et est à même de répondre en toute indépendance aux besoins d’accompagnement sur des problématiques d’infrastructures réseaux et systèmes filaires et sans fil.",
    description: [
      <p key="1">
        Nos consultants et chef de projets sont à même de vous assister dans
        l’évaluation des besoins, la recherche de solutions optimales, la
        formalisation de documents de consultation, et la réalisation de tous
        vos projets liés aux infrastructures de communications.
      </p>,
    ],
    domains: [
      "Très haut débit FTTx",
      "infrastructure Wi-Fi – radio & FH",
      "solution LPWan & Objet Connectés (IoT)",
      "maitrise & gestion des ICE",
      "RODP et domanialité",
      "gestion cartographie",
      "Cabling Systems",
      "analyse spectrale",
      "pré-évaluation et labélisation Ready to Service (R2S)",
    ],
    img: (
      <StaticImage
        src="../assets/imgs/expertises/infrastructures.jpg"
        height={770}
        placeholder="blurred"
        alt="Infrastructures"
        style={{ borderRadius: "20px" }}
      />
    ),
  },

  {
    name: "Solution sureté & sécurité",
    icon: <SolutionsSecurityIcon />,
    homePageDescription:
      "Les solutions de sureté et sécurité des biens et des personnes permettent une réactivité efficace même lors de situations critiques. Consultez nous !",
    introParagraph:
      "Quelles que soient les notions de sûreté et de sécurité, LM-Ingénierie vous accompagne pour identifier et cartographier vos besoins en matière de prévention et de gestion d’événements.",
    description: [
      <p key="1">
        Nos équipes auditent et analysent l’existant afin d’établir la feuille
        de route du dispositif et proposer une organisation efficace pour faire
        face à tout type d’événement.
      </p>,
    ],
    domains: [
      "Vidéo-protection espaces privés",
      "I & HAS (Intrusion and Hold-up Alarm System)",
      "Contrôle d’accès",
      "Détection périmétrique et périphérique",
      "Sécurité des personnes",
    ],
    img: (
      <StaticImage
        src="../assets/imgs/expertises/solution-surete-securite.jpg"
        height={770}
        placeholder="blurred"
        alt="Solution sureté & sécurité"
        style={{ borderRadius: "20px" }}
      />
    ),
  },

  {
    name: "Accompagnement au changement",
    icon: <ChangeIcon />,
    homePageDescription:
      "Comment piloter vos conduite du changement ? Comment positionner l’humain au coeur de vos réflexions ?",
    introParagraph: [
      <span key="1">
        Comment gérer et piloter votre conduite du changement&nbsp;?
      </span>,
      <br key="2" />,
      <span key="3">
        Comment positionner l’humain au centre des réflexions et des actions à
        mener&nbsp;?
      </span>,
      <br key="4" />,
      <span key="5">
        Comment identifier les freins et les leviers au coeur de vos projets et
        de vos organisations&nbsp;?
      </span>,
    ],
    description: [
      <p key="1">
        Nous vous proposons de relever avec vous l’ensemble de ces challenges en
        vous accompagnant sur les différentes phases suivantes&nbsp;:
      </p>,
      <ul key="2">
        <li>
          L’audit de l’existant avec l’analyse des risques et les études
          d’impacts
        </li>
        <li>Définition d’une stratégie de conduite du changement</li>
        <li>La communication afin de donner de la visibilité à votre projet</li>
        <li>
          La formation des équipes, la validation de leur adhésion au projet
        </li>
        <li>
          Le déploiement et son suivi avec la mise en place d’un ROI (baromètre
          du changement)
        </li>
        <li>Le REX, les préconisations et les ajustements si besoin</li>
      </ul>,
    ],
    img: (
      <StaticImage
        src="../assets/imgs/expertises/accompagnement-au-changement.jpg"
        height={770}
        placeholder="blurred"
        alt="Accompagnement au changement"
        style={{ borderRadius: "20px" }}
      />
    ),
  },

  {
    name: "SIG-Cartographie",
    icon: <CartographyIcon />,
    homePageDescription:
      "Nous mettons au service de vos projets, notre expertise de cartographe-Géomaticien. Nous vous aidons à cartographier vos territoires et bâtiments connectés.",
    description: [
      <h3 key="1">Cartographie – Géomaticien</h3>,
      <ul key="2">
        <li>Utilisation des logiciels SIG</li>
        <li>Utilisation des logiciels DAO</li>
        <li>Utilisation des SI propriétaires</li>
        <li>Analyse spatiale</li>
        <li>Cartographies</li>
        <li>Web Mapping</li>
        <li>Photomontage</li>
      </ul>,
      <h3 key="3">Etude FTTH/FTTO</h3>,
      <ul key="4">
        <li>Schémas directeur</li>
        <li>Ingénierie THD, FTTH, Collecte, MED</li>
        <li>Conception du réseau</li>
      </ul>,
    ],
    img: (
      <StaticImage
        src="../assets/imgs/expertises/SIG-Cartographie.jpg"
        height={770}
        placeholder="blurred"
        alt="SIG-Cartographie"
        style={{ borderRadius: "20px" }}
      />
    ),
  },
];

export default expertises;
