import React from 'react';
import styled from 'styled-components'

const StyledButton = styled.button`
  color: ${props => props.secondary ? props.theme.colors.black : props.theme.colors.white};
  background-color: ${props => (props.secondary || props.white) ? 'transparent' : props.theme.colors.brand};
  border: ${props => props.secondary ? '2px solid ' + props.theme.colors.black : props.white ? '2px solid ' + props.theme.colors.white : 'none'};
  height: 42px;
  border-radius: 42px;
  outline: none;
  cursor: pointer;
  font-weight: 900;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: ${props => props.center ? '0 auto' : 'unset'};
`

const Button = ({ secondary, white, children, className, submit, center, onClick }) => {
  if (submit) return (
    <StyledButton
      center={center}
      secondary={secondary}
      white={white}
      className={className}
      type="submit"
    >
      {children}
    </StyledButton>
  )
  if (onClick) return (
    <StyledButton
      onClick={() => onClick()}
      center={center}
      secondary={secondary}
      white={white}

      className={className}
    >
      {children}
    </StyledButton>
  )
  return <StyledButton
    center={center}
    secondary={secondary}
    white={white}
    className={className}
  >
    {children}
  </StyledButton>
}

export default Button;
