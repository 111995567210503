import React from 'react'

const ArrowSelect = () => {
  return (
    <svg width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.37109 2.48688L9.77369 7.62842L17.3711 2.48688" stroke="#C00000" strokeWidth="3" strokeLinecap="round" />
    </svg>
  )
}

export default ArrowSelect



